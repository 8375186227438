import type { ssrLoggerType } from './ssrLogger.plugin.server'

let logger: ssrLoggerType | undefined

export const setLogger = (value: ssrLoggerType) => {
  logger = value
}

export const getLogger = () => {
  return logger
}
